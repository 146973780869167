import { Modal, Tabs } from "antd";
import React, { useState } from "react";
import { AuthTabBase, AuthTabType } from "../tab/base";
import { AuthTabParams } from "..";

type LoginModalUIProps = {
  title?: string;
  description?: string | React.ReactNode;
  open?: boolean;
  defaultTab?: AuthTabParams;
  onCancel?: () => void;
};

export const LoginModalUI: React.FC<LoginModalUIProps> = ({
  title,
  description,
  open,
  defaultTab,
  onCancel,
}) => {
  const [enableTabs, setEnableTabs] = useState(true);

  const tabs = [
    {
      key: AuthTabParams.SignUp,
      label: `Sign up`,
      disabled: !enableTabs,
      children: <AuthTabBase type={AuthTabType.SignUp} setEnableTabs={setEnableTabs} />,
    },
    {
      key: AuthTabParams.Login,
      label: `Log in`,
      disabled: !enableTabs,
      children: (
        <AuthTabBase type={AuthTabType.Login} setEnableTabs={setEnableTabs} showForgotPassword />
      ),
    },
    {
      key: AuthTabParams.SSO,
      label: `SSO Log in`,
      disabled: !enableTabs,
      children: <AuthTabBase type={AuthTabType.SSO} setEnableTabs={setEnableTabs} />,
    },
  ];

  return (
    <Modal
      footer={null}
      onCancel={onCancel}
      title={title}
      open={open}
      style={{ maxWidth: "700px", minWidth: "650px" }}
    >
      {description}
      <Tabs
        size="middle"
        defaultActiveKey={defaultTab}
        centered
        destroyInactiveTabPane
        items={tabs}
      />
    </Modal>
  );
};
