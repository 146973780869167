import { useMemo } from "react";
import { useRouter } from "next/router";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { LoginModalUI } from "./modal-ui";

export enum AuthTabParams {
  Login = "login",
  SignUp = "signup",
  SSO = "sso",
}

function isAuthTabParam(value: string | string[] | undefined): value is AuthTabParams {
  return Object.values(AuthTabParams).includes(value as AuthTabParams);
}

export const LoginModal: React.FC = () => {
  const router = useRouter();
  const loginTab = useMemo(() => router.query?.login, [router.query.login]);

  if (!isAuthTabParam(loginTab)) return null;

  return (
    <LoginModalUI
      onCancel={() => router.replace(INDEX_ROUTE.getHref(), undefined, { shallow: true })}
      open={!!loginTab}
      defaultTab={loginTab}
    />
  );
};
