import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Button, Form, Input, Popover, theme } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { sendPasswordResetEmail } from "firebase/auth";
import { getAnalytics } from "logic/analytics/analytics";
import { getFirebaseAuth } from "logic/internals/apis/firebase/firebase-auth";
import { TransportPhase } from "logic/internals/transports/transported-data/transport-phases";
import { TransportedData } from "logic/internals/transports/transported-data/transported-data-types";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { z } from "zod";
import { AuthTabParams } from "..";

export function ForgotPassword() {
  const { token } = theme.useToken();
  const analytics = getAnalytics();
  const [show, setShow] = useState(false);

  const [forgotPasswordRequest, setForgotPasswordRequest] = useState<
    TransportedData<undefined> | { status: "user-not-found" } | { status: "too-many-requests" }
  >({
    status: TransportPhase.NotInitialized,
  });

  const form = useForm<{ email: string }>({
    resolver: zodResolver(
      z.object({
        email: z.string().email(),
      })
    ),
  });

  return (
    <Popover
      placement="top"
      onOpenChange={(e) => {
        setForgotPasswordRequest({ status: TransportPhase.NotInitialized });
        setShow(e);
        form.reset();
      }}
      open={show}
      title={"Insert your email so we can send you a link to reset your password"}
      content={
        <form
          onSubmit={form.handleSubmit(async (data) => {
            analytics.track("account-settings:reset-password");

            setForgotPasswordRequest({ status: TransportPhase.Loading });

            try {
              await sendPasswordResetEmail(getFirebaseAuth(), data.email, {
                url: `${location.origin}${INDEX_ROUTE.getHref()}?login=${AuthTabParams.Login}`,
              });

              setForgotPasswordRequest({
                status: TransportPhase.Done,
                data: undefined,
              });
            } catch (_err) {
              const error = _err as { [key: string]: unknown };

              if (error.code === "auth/user-not-found") {
                setForgotPasswordRequest({ status: "user-not-found" });
              } else if (error.code === "auth/too-many-requests") {
                setForgotPasswordRequest({
                  status: "too-many-requests",
                });
              } else {
                throw error;
              }
            }
          })}
        >
          <Form.Item
            hasFeedback={!!form.formState.errors.email?.message}
            validateStatus={form.formState.errors.email?.message ? "error" : undefined}
            help={form.formState.errors.email?.message}
          >
            <Controller
              name="email"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <Input.Group compact style={{ display: "flex" }}>
                  <Input
                    style={{ flex: "1 1 auto" }}
                    prefix={<AntIcon icon={faEnvelope} />}
                    placeholder="Email"
                    onChange={onChange}
                    value={value}
                  />
                  <Button
                    loading={forgotPasswordRequest.status === TransportPhase.Loading}
                    type="primary"
                    htmlType="submit"
                  >
                    Reset password
                  </Button>
                </Input.Group>
              )}
            />
          </Form.Item>
          {forgotPasswordRequest.status === "user-not-found" ? (
            <Alert type="error" message="This email is not registered." />
          ) : null}
          {forgotPasswordRequest.status === TransportPhase.Done ? (
            <Alert
              type="info"
              message="A link for resetting your password has been sent to your email."
            />
          ) : null}
          {forgotPasswordRequest.status === "too-many-requests" ? (
            <Alert
              type="error"
              message="Too many password reset emails were sent to your email."
              description="Wait a few moments before sending a new password reset email."
            />
          ) : null}
        </form>
      }
      trigger="click"
    >
      <Button
        type="link"
        // onClick={(e) => {
        //   e.preventDefault();
        // }}
        style={{ marginRight: token.marginSM }}
      >
        Forgot password?
      </Button>
    </Popover>
  );
}
