import { LoginModalUI } from "templates/index/login/modal-ui";
import { useAuthContext } from "logic/auth-context";
import { throwError } from "logic/internals/utils/throw-error";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";

type ContextValue = {
  showSignupRequiredAlert: () => void;
};
const SignupRequiredContext = createContext<ContextValue | null>(null);

export function SignupRequiredAlert(props: { isMobile?: boolean; children: ReactNode }) {
  const auth = useAuthContext();
  const user = auth.session.user;

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (user) {
      setShowAlert(false);
    }
  }, [user]);

  return (
    <SignupRequiredContext.Provider value={{ showSignupRequiredAlert: () => setShowAlert(true) }}>
      <LoginModalUI
        open={showAlert}
        onCancel={() => setShowAlert(false)}
        title="Sign up"
        description={
          <>
            <p className="mb-2 font-bold">Welcome!</p>
            <ul className="list-disc pl-6">
              <li>
                <strong>Enjoy 2 free interviews</strong> before subscribing to a plan.
              </li>
              <li>
                <strong>Subscribe anytime</strong> to continue using our service.
              </li>
              <li>
                <strong>7-day free trial</strong> upon subscription—cancel within this period for a
                full refund.
              </li>
            </ul>
          </>
        }
      />
      {props.children}
    </SignupRequiredContext.Provider>
  );
}

export function useSignupRequiredAlert() {
  return useContext(SignupRequiredContext) || throwError();
}
